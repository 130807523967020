import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../../assets/css/style_coming.css';
export default function Secone(){
    return (
        <>

             <Container fluid className="__bg-comingsoon">
                <Container className="__flex-align-items">
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__comingsoon-sl">
                                <p className="text-center">
                                   COMING SOON
                                </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__image_logo_cm mt-3">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/logo-am-love.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                            <div className="d-flex justify-content-center wl-am-fr mt-3">
                                <a href="/wlam">
                                <Button variant="primary" size="lg">
                                    JOIN WAITING LIST
                                </Button>{' '}
                                </a>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </Container>
        </>
    )
}

